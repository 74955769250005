.seguimiento-group {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: auto;
    margin-bottom: 2rem;

    button {
        padding: 1.2rem;
        border-radius: 0;
        width: 80%;
    }
}

.seguimiento-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: var(--color-apoyo);
    display: inline-block;
    opacity: .5;
    margin-right: 4rem;
}

.seguimiento-finalizado {
    max-width: 600px;
    margin: 5rem auto;
    h2 {
        text-align: center;
        color: var(--color-apoyo2);
        font-size: 2rem;
    }

    h3 {
        font-size: 2rem;
        color: var(--color-principal);
        margin: 3rem 0;
    }

    .seguimiento-estado {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-principal);
        font-size: 1.5rem;
    }
}