.gestion-docente-proyectos h2{
    font-weight: 600;
}

.gestion-docente-proyectos h2 .btn-circular{
    margin-left: 1rem;
}

.gestion-docente-seccion {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
}

.gestion-docente-seccion .card{
    width: 45%;
    margin-bottom: 2rem;
    margin-right: 2rem;
}

.confirm-message {
    font-size: 1.2rem;
}