.evaluacion-ver-nota {
    float: right;
}

.socializacion-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    background-color: #fde9c8;
    color: var(--color-principal);
    padding: 1rem;

    .socializacion-fecha span:first-child{
        margin-right: 1rem;
    }
}

.evaluacion-notas {
    font-weight: 400;
    font-size: 1rem;
    color: var(--color-accion);
}

.table-result {
    width: 50%;
    margin-left: auto;
    border-spacing: 0;
    td {
        border: 1px solid var(--color-apoyo);
        padding: 0.5rem;
    }
}