.login-section{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.presentacion h1 {
    margin: 1rem 0;
    font-size: 2.5em;
    font-weight: 600;
}
.presentacion h5 {
    font-size: 1rem;
}
.login-icon {
    font-size: 1.7em;
    vertical-align: middle;
}
#logo_login {
    width: 15rem;
}

@media screen and (min-width: 2300px) {
    .presentacion h1 {
        margin: 1rem 0;
        font-size: 4em;
    }
    .presentacion h5 {
        font-size: 2em;
    }
}
@media screen and (max-height: 667px){
    .presentacion h1 {
        font-size: 2rem;
    }
    .presentacion h5 {
        font-size: 1em;
    }
}
@media screen and (max-width: 280px) {
    .presentacion h5 {
        font-size: 0.7em;
    }
}