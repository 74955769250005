.info-field {
    font-size: 2rem;
    font-weight: 400;
    color: var(--color-principal);
    display: flex;
    margin-bottom: 1rem;

    span{
        margin-left: 1rem;
        display: flex;
        align-items: center;
    }

    &--small {
        font-size: 1rem;
    }

    &--medium {
        font-size: 1.5rem;
    }

    &--block {
        flex-direction: column;
        span {
            margin-bottom: 1rem;
        }
    }
}

.info-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.info-result {
    color: var(--color-principal);
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 1.5rem;

    &-value {
        align-items: center;
        border-radius: 50%;
        border: 1px solid var(--color-principal);
        color: #000;
        display: inline-block;
        display: inline-flex;
        height: 3rem;
        justify-content: center;
        margin-left: 0.5rem;
        padding: 3px;
        width: 3rem;
    }

    @media screen and (max-width: 500px) {
        font-size: 1.5rem;
    }
}

