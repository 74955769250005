/* INICIALIZACIÓN */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: 'Inter', sans-serif;
}

a:link, a:visited, a:active {
  text-decoration: none;
}

/* COLORES */

:root {
  --color-principal: #3170B2;
  --color-accion: #F9A825;
  --color-apoyo: #5DADEC;
  --color-apoyo2: #03A696;
  --color-apoyo3: #6D94FB;
  --color-navegacion: #FF9470;
  --color-bgapoyo: #eaf4fd;
}

/* GENERALIDADES DE ETIQUETAS */

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-width: 100vw;
}

.main-container {
  display: flex;
  width: calc(100% - 300px);
  margin-left: 300px;
}

.container {
  flex-grow: 1;
  padding: 2rem;
  width: 100%;
}

/* Títulos / Textos*/
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h1, h2, label {
  color: var(--color-principal);
}

/* Botones */

::-webkit-file-upload-button{
  background-color: var(--color-apoyo2);
}

input, select, textarea {
  padding: 0.5rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid var(--color-principal);
  color: var(--color-principal);
}
::placeholder {
  color: var(--color-apoyo);
}

.form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.form-group--big{
  flex-grow: 1;
  margin-bottom: 2rem;
}

.form-group > *{
  flex-grow: 1;
  margin-left: 1rem;
}

.form-group > *:first-child{
  margin-left: 0;
}

.form-group a {
  color: var(--color-apoyo);
}

.pt0 {
  padding-top: 0;
}

.mt0 {
  margin-top: 0;
}

.mt2 {
  margin-top: 2rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}


@media screen and (max-width: 800px){
  .container {
    padding: 1.5rem;
  }

  .main-container {
    margin-left: 0;
    width: 100%;
  }
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


.acciones {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  max-width: 700px;
}

.acciones button {
  margin-right: 1rem;
}

.btn-circular {
  background-color: #000;
  color: #fff;
  width: 42px;
  height: 42px;
  outline: none;
  border: none;
  border-radius: 50%;
  font-weight: 400;
  font-size: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


@media screen and (max-width: 600px) {
  .acciones div,
  .acciones {
      display: flex;
      flex-direction: column;
  }

  .acciones button{
      margin-top: 1rem;
  }
}