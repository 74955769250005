.table {
  &-search {
    max-width: 300px;
    width: 100%;
    margin-left: auto;
  }

  &-container{
    table {
        margin: 2rem 0;
        width: 100%;
        border-spacing: 0;
        border: 1px solid var(--color-apoyo);
        table-layout:fixed;
    }

    th{
        background-color: var(--color-apoyo);
        color: white;
        padding: 0.2rem;
        .table-button-add {
          right: 0;
          top: 0;
          margin-top: -15px;
          z-index: 1;

          &--left {
            left: 0;
          }
        }
    }
    td {
        min-width: 50vw;
        border: 1px solid var(--color-apoyo);
        border-right: none;
        border-bottom: none;

        &:first-child {
            border-left: none;
        }
    }

    th, td {
      position: relative;
      padding: 1rem;
    }
  }

  &-content {
    width: 100%;
    overflow-x: scroll;
  }
}

.pointer {
  cursor: pointer;
  &:hover {
    background-color: var(--color-bgapoyo);
  }
}

@media (max-width: 630px) {
  .table-content table thead tr th:nth-child(n+4),
  .table-content table tbody tr td:nth-child(n+4) {
      display: none;
  }
}

.table-button-add {
  position: absolute;
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: var(--color-principal);
  outline: 0;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}
