.button {
  color: #fff;
  outline: none;
  border: none;
  border-radius: 10px;
  min-width: 8rem;
  padding: 8px;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }

  &--warning,
  &--submit {
    background-color: var(--color-accion);
  }

  &--primary {
    background-color: var(--color-apoyo);
  }

  &--medium {
    border-radius: 10px;
    padding: 1rem;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--big {
    font-size: 1.5rem;
  }
}