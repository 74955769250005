.cargar-listas h2{
    font-size: 2rem;
    font-weight: 400;
    margin: 2rem 0;
}

.acciones-listas {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
}

.file-container {
    justify-content: center;
}

.acciones-listas button {
    margin-right: 1rem;
}

.periodo_academico{
    width: 100%;
    max-width: 450px;
    margin: auto;
}

@media screen and (max-width: 600px) {
    .acciones-listas div,
    .acciones-listas,
    .periodo_academico{
        display: flex;
        flex-direction: column;
    }

    .acciones-listas button{
        margin-top: 1rem;
    }
}