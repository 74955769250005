.checkbox {
  &-label {
    margin-left: 1rem;
  }

  &-container {
    color: var(--color-principal);
    display: block;
    position: relative;
    padding-left: 35px;
    font-size: 1rem;
    user-select: none;
    text-align: left;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkbox-checkmark {
        background-color: #2196F3;
        &:after {
          display: block;
        }
      }
    }  
  }

  &-content {
    display: inline-block;
    cursor: pointer;
    margin-left: 0.5rem;
  }

  &-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid var(--color-principal);
    margin-right: 1rem;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 1px;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &:hover input ~ .checkbox-checkmark {
    background-color: #fff;
    border: 1px solid #2196F3;
  }

  &--big {
    font-size: 1.5rem;
    .checkbox-checkmark {
      height: 30px;
      width: 30px;

      &:after {
        left: 9px;
        top: 3px;
        width: 9px;
        height: 14px;
      }
    }
  }
}
