.autores-group {
    max-width: 80%;
    margin-bottom: 5rem;
    margin: auto;
    .info-field.info-field--small span:last-child {
        width: 100%;
    }

    span:first-child {
        width: 6rem;
    }
}

.autores-add {
    width: 40px;
    height: 40px;
    min-width: auto;
    border-radius: 50%;
    position: absolute;
    right: 0;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.fases {
    display: flex;
    align-items: center;
    flex-direction: column;
    h2 {
        color: var(--color-accion);
        font-size: 2.5rem;
        margin-bottom: 3rem;

        &.fase-terminada {
            color: var(--color-apoyo2);
        }
    }

    h3 {
        color: var(--color-principal);
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .fases-grupo {
        color: var(--color-apoyo);
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        cursor: pointer;
    }

    .fase-circulo {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        border: 1px solid var(--color-apoyo);
        border-radius: 50%;
        margin-right: 1rem;

        &--active {
            background: var(--color-apoyo2);
        }
    }

    .fase-descripcion {
        &--curso,
        &--finalizada {
            margin-top: 1rem;
            padding: 2rem;
            color: var(--color-principal);
            font-size: 1.3rem;
            border-radius: .8rem;
        }

        &--curso {
            background-color: #d5e9fa;
        }
    }   
}

.institucionalizacion-asesor {
    display: flex;
    justify-content: center;
    span {
        display: block;
    }
}

.actions {
    float: right;
}