
.arrow {
    font-size: 1.5rem;
    color: var(--color-apoyo);

    &-content {
      margin: auto;
      display: block;
      width: 10px;
    }

    &-horizontal,
    &-vertical {
      height: 78px;
      background: var(--color-apoyo);
      width: 3px;
      display: block;
      position: relative;
      left: 10.5px;
      bottom: -6px;
    }

    &--right {
      .arrow-vertical {
        left: 0;
        bottom: 18px;
      }

      .arrow-horizontal {
        height: 3px;
        width: 25px;
        left: -25px;
        top: -21px;
      }
    }
  }
  
  
  
  