.propuesta-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circular-action {
  background-color: #02a696;
  color: #fff;
  padding: 0.5rem;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--reject {
    background-color: #de4a4b;
  }
}

.propuesta-actions {
  display: flex;

  button {
    width: 50px;
    height: 50px;
    min-width: 0;
    overflow: hidden;
    border-radius: 50%;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }
}

.propuestas-cards {
  width: 100%;
  max-width: 500px;
  > * {
    margin-bottom: 1rem;
  }
}

.modal-content {
  text-align: center;
  .modal-text {
    font-size: 1.5rem;
  }

  button {
    margin-top: 1.5rem;
  }

  &--notification {
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-text {
      margin-left: 1rem;
    }
  }
}

.propuestas-simple-text {
  color: var(--color-apoyo);
  font-size: 1.2rem;
  margin: 1.5rem;
}