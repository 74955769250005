.input {
  &-label {
    display: block;
    margin-bottom: 1rem;
  }
  
  &-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    textarea,
    input {
      flex-grow: 1;
    }

    textarea {
      resize: vertical;
      min-height: 6rem;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    font-size: 2rem;
    margin-right: 2rem;
  }

  &--inline {
    flex-direction: row;
    align-items: center;

    textarea,
    input {
      margin-left: 1rem;
    }

    .input-label {
      margin: 0;
    }
  }

  &--big {
    font-size: 30px;
    textarea,
    input {
      padding: 0.6rem;
      font-size: 1.5rem;
    }
  }
}

[readonly] {
  background-color: #e5e5e5 !important;
}
