#panel {
    padding: 0.9rem;
    background-color: var(--color-bgapoyo);
    overflow: auto;
    box-shadow: 1px 0 4px rgb(241, 241, 241);
    height: 100vh;
    position: relative;
    width: 300px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
#panel main {
    margin: 4rem 0;
}
#panel main h2 {
    margin: 2em 0;
    font-size: 1.3rem;
    font-weight: 700;
}

#contenedor_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#contenedor_header h3 {
    color: var(--color-principal);
}

#contenedor_presentacion, .List_Pages {
    display: flex;
    align-items: center;
}

#contenedor_presentacion h3 {
    margin: 0 0.9rem;
    font-weight: 700;
    font-size: 20px;
}

.panel-icon {
    font-size: 1.5rem;
    color: var(--color-principal);
    cursor: pointer;
}

#panel footer ul li {
    display: inline-block;
    vertical-align: middle;
}
#panel footer h4 {
    margin: 0 1rem;
    font-size: 1.1em;
}

#panel footer div{
    position: absolute;
    bottom: 0;
    margin: 2rem 2.5rem;
}

.pages {
    margin: 3rem 1rem;
    display: block;
}

.List_Pages {
    margin: 2rem 0;
    color: var(--color-principal);
}

.List_Pages h3{
    margin-left: 0.8em;
}

.List_Pages:hover {
    color: var(--color-accion);
}

.Menu_Icon {
    font-size: 2rem;
    color: white;
}

.Menu_Button {
    cursor: pointer;
    background-color: var(--color-navegacion);
    position: absolute;
    right: 0;
    margin: 1rem;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 2rem;
    outline: none;
    z-index: 1;
    display: none;
}

.close-button {
    margin-top: 3rem
}

.btn-logout {
    display: block;
    width: calc(100% - 2rem);
    position: absolute;
    bottom: 0;
    margin: 1rem 0;
}

#logo_panel {
    width: 2rem;
}

.Page_Icon {
    font-size: 20px;
}

.notifications {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: white;
    height: 80vh;
    width: 500px;
    margin-left: -250px;
    margin-top: -40vh;
    border: 1px solid var(--color-navegacion);
    text-align: center;
    font-size: 1.2rem;
    display: none;
    z-index: 100;
}

.notifications-body {
    position: relative;
    padding: 2rem;
}

.notifications-header {
    position: relative;
    height: 2rem;
}

.notifications-header button{
    display: block;
}

.notifications--show {
    display: block;
}

@media screen and (max-width: 800px) {
    .Menu_Button {
        display: block;
    }

    #panel {
        max-width: 400px;
        width: 100%;
        left: 0;
        transition: left .5s ease-out;
    }

    .List_Pages h3{
        font-size: 1.5rem;
    }
    .Page_Icon {
        font-size: 2rem;
    }

    #panel.panel--collapsed {
        left: -100%;
    }
}

@media only screen and (max-width: 425px) {
    #panel {
        width: 100%;
        height: 100vh;
    }

    .notifications {
        width: 100%;
        left: 0;
        top: 0;
        margin: 0;
        height: 100%;
    }
}

