.seguimiento-detalle {
  .info-field span:last-child {
    font-size: 1.3rem;
    color: var(--color-apoyo);
    font-weight: 600;
  }
}

.seguimiento-estudiantes {
  display: flex;
  flex-wrap: wrap;
  > ul {
    width: 40%;
    margin: 1rem 2rem;
  }
}

.tutorias-realizadas {
  button {
    margin: 1rem 2rem; 
    background-color: #02a696;

    &.btn-nueva-nota {
      margin-top: 2rem;
      background-color: var(--color-accion);
    }
  }

  .modal-content {
    margin-bottom: 2rem;
  }
}

.seguimiento-institucionalizacion {
  display: flex;
  align-items: center;
  button {
    margin: 1rem 2rem;
    background-color: #fff;
    border: 1px solid var(--color-accion);
    color: var(--color-accion);
  }

  a {
    color: var(--color-apoyo);
  }
}


.circular-action {
  background-color: #02a696;
  color: #fff;
  padding: 0.5rem;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--reject {
    background-color: #de4a4b;
  }
}


.identation {
  margin-left: 2rem;
}

.agregar-compromisos {
  display: flex;
  width: 100%;
  position: relative;

  display: flex;
  justify-content: center

  label {

    flex-grow: 1;
  }

  > button {
    position: absolute;
    margin-left: 1rem;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    min-width: auto;
    right: 0;
    top: -3rem;
  }
}

.todos-compromisos {
  width: 100%;
  label {

    
    margin-top: 2rem;
  }
}

.evaluacion-documento {
  display: flex; 
  width: 100%;
  max-width: 500px;
  margin-left: 2rem;
  & > button:first-child {
    margin-right: 1rem;
  }
}

.resumen-evaluacion {
  font-size: 1rem;
  font-weight: 700;
  color: var(--color-accion);
  display: flex;
  float: right;
}