.file {
  &-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  &-button {
    background-color: var(--color-apoyo2);
    color: #fff;
    border: none;
    font-weight: 600;
    font-size: 1rem;
    padding: .5rem 1.5rem;
    border-radius: 10px;
    margin-right: 1rem;
  }

  &-loaded {
    border-radius: 10px;
    padding: .5rem 1.5rem;
    font-size: 1rem;
    border: 1px solid var(--color-apoyo2);
    background-color: #fff;
  }
}



/* input, select {
  padding: 0.5rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid var(--color-principal);
  color: var(--color-principal);
} */