.home main {
    max-width: 1400px;
    margin: auto;
    padding: 1.5rem;
}

.home-avatar {
    background-color: var(--color-apoyo2);
    width: 115px;
    height: 115px;
    border-radius: 50%;
    position: fixed; 
    top: 36px;
    right: 36px; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    color: #fff;
    cursor: pointer;
    flex-direction: column;
    z-index: 10;
}

.home-menu {
    position: relative;
    width: 100%;
    display: none;

    ul {
        position: absolute;
        font-size: 1rem;
        width: 160px;
        margin-left: -80px;
        left: 50%;
        top: 2.5rem;
        background: var(--color-principal);
    }

    li {
        padding: 5px 10px;
        border-bottom: 1px solid var(--color-accion);
        &:hover {
            opacity: .7;
        }
    }

    a {
        color: #fff;
    }
}

.home-menu--open{
    display: block;
}

.home-header {
    background-image: url(../../assets/img/unsplash.png);
    overflow: hidden;
    position: relative;
    height: 580px;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--color-principal);
        opacity: .7;
    }
}

.home-header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 9;
    color: #fff;
    height: 100%;

    h1 {
        font-size: 128px;
        color: #fff;
    }

    span {
        font-size: 24px;
    }
}

.home-title {
    color: var(--color-apoyo3);
    font-size: 40px;
    margin-bottom: 1.5rem;
}

.home-content {
    display: flex;
    justify-content: center;
    margin: 3rem;
    font-size: 24px;
}

.home-section {
    margin-bottom: 2rem;
}

.home-calendar-item {
    border: 15px solid var(--color-accion);
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 25%;
    min-width: 200px;
    margin: 1rem;
}

.home_steps{
    padding: 15px;
    display: flex;
    flex-direction: column;
    width: 25%;
    margin: 1rem;
}
.home-calendar-month {
    font-size: 40px;
    color: #FFE500;
}

.home-calendar-year {
    font-size: 24px;
    color: #FFE500;
}

.home-calendar-description {
    font-size: 24px;
    color: var(--color-apoyo3);
    margin-top: 1rem;
}

.home-process img {
    margin: auto;
}

.home-header-content img{
    width: 25rem;
}

@media screen and (max-width: 1100px){
    .home-content {
        margin: auto;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 800px){
    .home-header-content {
        transform: scale(.7);
    }
}

@media screen and (max-width: 600px){
    .home-header-content {
        transform: scale(.5);
        display: flex;
        flex-direction: column;
    }

    .home-calendar-item {
        width: 100%;
    }
    .home-content img > .home_steps{
        transform: scale(.4);
    }
    .home_steps{
        
        display:contents;
    }
}
