.propuesta-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.propuesta-group {
    display: flex;
    .block {
        flex-grow: 1;
        width: 50%;
        margin-right: 2rem;
        &:last-child {
            margin-right: 0;
        }
    }

    .form-group{
        width: 150px;
    }

    @media screen and (max-width: 1100px) {
        flex-direction: column;
        .block { 
            margin-right: 0;
            width: 100%;
        }
    }
}

.propuesta-confirmacion {
    text-align: center;
    
    h2 {
        margin-top: 5rem;
        font-size: 2.5rem;
        color: var(--color-apoyo2);
    }

    h3 {
        margin-top: 2rem;
        font-size: 1.5rem;
        color: var(--color-apoyo);
    }
}

.acciones-propuesta {
    display: flex;
    justify-content: flex-end;
    button {
        margin-left: 1rem;
    }
}