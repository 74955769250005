.block {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    &-collapse {
        float: right;
        display: flex;
        align-items: center;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        margin-right: 1rem;
    }

    &-header {
        background: var(--color-apoyo);
        color: #fff;
        padding: 1rem;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 700;
        position: relative;
    }

    &-body {
        position: relative;
        flex-grow: 1;
        padding: 1rem;
        margin-top: 1rem;
        border: 1px solid var(--color-apoyo);
        padding-bottom: 4rem;
    }

    &-footer {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 1rem;
        & > * {
            margin-right: .5rem;
        }
    }
}