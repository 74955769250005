.modal {
    background-color: #0000006e;
    position: fixed;
    padding: 2.5rem 1rem;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;

    &-container {
        background-color: #fff;
        position: relative;
        left: 50%;
        top: 30vh;
        margin-left: -150px;
        width: 500px;
        height: auto;
        padding: 3rem;
    }

    &-close {
        width: 45px;
        height: 45px;
        font-size: 2rem;
        outline: 0;
        border: none;
        background-color: transparent;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }
    // color: var(--color-principal);
    // &-close {
    //     background-color: #DE4B4B;
    //     position: absolute;
    //     top: -10px;
    //     right: 0;
    // }

    // &-header {
    //     font-weight: 600;
    //     margin-bottom: 2rem;
    // }
}