.submenu-comunicados {
    width: 100%;
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.submenu-comunicados button{
    font-size: 2rem;
    margin-top: 3rem;
    padding: 2rem;
}

.comunicados .form-group {
    max-width: 600px;
}


@media screen and (max-width: 600px) {

    .comunicados .form-group {
        flex-direction: column;
    }

    .acciones div,
    .acciones {
        display: flex;
        flex-direction: column;
    }

    .acciones button{
        margin-top: 1rem;
    }
}
