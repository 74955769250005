.select {
  &-label {
    display: block;
    margin-bottom: 1rem;
  }

  &-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    select {
      flex-grow: 1;
    }
  }

  &--inline {
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;

    select {
      margin-left: 1rem;
    }

    .select-label {
      margin: 0;
    }
  }

  &--big {
    font-size: 30px;

    select {
      padding: 1rem;
      font-size: 1.5rem;
    }
  }
}
