.gestion-proyecto-originalidad {
    text-align: center;
    color: var(--color-principal);
    font-size: 1.5rem;
    .form-group {
        margin-top: 1rem;
        justify-content: center;
        label {
            max-width: 6rem;
        }
    }
}