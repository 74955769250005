.container h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.grades-content {
  display: flex;
  flex-wrap: wrap;
}

.grades-menu {
  margin-right: 2rem;
  width: 400px;

  button {
    margin-bottom: 1rem;
  }
}

.dates-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.dates-item {
  background: #f9a82540;
  border-radius: 5px;
  color: var(--color-principal);
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-right: 1rem;
  min-width: 250px;
  padding: 2rem;
  width: 30%;
}

.dates-item-description {
  font-weight: 700;
}

@media screen and (max-width: 1300px) {
  .grades-content {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 500px) {
  .grades-content {
    flex-direction: column-reverse;
  }

  .grades-menu {
    width: 100%;
  }

  .container h1{
    font-size: 1.5rem;
  }
}
