.container h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.dates-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.dates-item {
  background: #f9a82540;
  border-radius: 5px;
  color: var(--color-principal);
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-right: 1rem;
  min-width: 250px;
  padding: 2rem;
  width: 30%;
}

.dates-item-description {
  font-weight: 700;
}

.docente-inicio {
  display: flex;
  button {
    margin-right: 1rem;
    flex-grow: 1;
    padding: 2rem;
  }


  @media screen and (max-width: 1100px) {
    flex-direction: column;
    button {
      margin-top: 1rem;
      flex-grow: 1;
      padding: 4rem;
    }
  }
}


@media screen and (max-width: 500px) {
  .container h1{
    font-size: 1.5rem;
  }
}
