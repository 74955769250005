.Gestion_Table{
    margin: 2rem;
}
.Gestion_Table tr th{
    background-color: var(--color-apoyo);
    color: white;
    padding: 0.2rem;
}
.Gestion_Table tr td:not(#checkbox) {
    min-width: 15rem;
}
