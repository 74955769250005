.container h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.dates-content {
  width: 100%;
}

.dates-item {
  background: #f9a82540;
  border-radius: 5px;
  color: var(--color-principal);
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-right: 1rem;
  min-width: 250px;
  padding: 2rem;
  width: 30%;
}

.dates-item-description {
  font-weight: 700;
}


.estudiante-menu {
  display: flex;
  width: 100%;
}

.estudiante-menu-group {
  margin-right: 5rem;
  display: flex;
  flex-direction: column;
}

.estudiante-menu-last {
  position: relative;
  .arrow {
    position: absolute;
    bottom: 18px;
    right: -45px;
  }
}

.btn-lock {
  background-color: #fff;
  color: var(--color-principal);
  border: 1px solid var(--color-principal);
  border-radius: 0;
}

.btn-submit {
  background-color: var(--color-accion);
  color: #fff;
  border: 1px solid var(--color-principal);
  border-radius: 0;
}

@media screen and (max-width: 500px) {
  .container h1{
    font-size: 1.5rem;
  }
}
