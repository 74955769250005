.card {
    background-color: #F9A82540;
    position: relative;
    padding: 2.5rem 1rem;
    color: var(--color-principal);

    &-container {
        cursor: pointer;
    }

    &-close {
        background-color: #DE4B4B;
        position: absolute;
        top: -10px;
        right: 0;
    }

    &-header {
        font-weight: 600;
        margin-bottom: 2rem;
    }
}